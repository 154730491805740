<template>
  <svg height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd">
      <circle cx="9" cy="9" style="fill: var(--v-bus_light_grey-base)" r="9" />
      <path
        d="m12.7058824 8.47058824v1.05882352h-7.41176475v-1.05882352z"
        style="fill: var(--v-bus_white-base)"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>
