<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path
        d="M15,20 C15,21.6568542 13.6568542,23 12,23 C10.3431458,23 9,21.6568542 9,20 L1.63162684,20 C1.28280972,20 1.00003757,19.7172278 1.00003757,19.3684107 L1.00006977,19.2772634 C1.00006977,18.9458701 1.16424968,18.6360024 1.43844507,18.4498884 C3.81281502,16.8382512 5,15.0391623 5,13.0526219 L5,8 C5,4.13400675 8.13400675,1 12,1 C15.8659932,1 19,4.13400675 19,8 L19,13.0526219 C19,15.0391717 20.1872073,16.8382682 22.561622,18.4499112 C22.8358152,18.6360417 23,18.945916 23,19.2773168 L23,19.3684107 C23,19.7172278 22.7172278,20 22.3684107,20 L15,20 Z M13,20 L11,20 C11,20.5522847 11.4477153,21 12,21 C12.5522847,21 13,20.5522847 13,20 Z M17,13.0526219 L17,8 C17,5.23857625 14.7614237,3 12,3 C9.23857625,3 7,5.23857625 7,8 L7,13.0526219 C7,14.8679344 6.31078995,16.5218097 5.00485176,18 L18.9951622,18 C17.689215,16.5218107 17,14.8679359 17,13.0526219 Z"
        style="fill: var(--v-secondary-base)"
        fill-rule="nonzero"
      ></path>
    </g>
  </svg>
</template>
