<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="80px"
    height="80px"
    viewBox="0 0 80 80"
    version="1.1"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(17.000000, 6.000000)" style="fill: var(--v-neutral40-base)">
        <path
          d="M45,0 C45.5522847,0 46,0.44771525 46,1 L46,59 C46,59.5522847 45.5522847,60 45,60 L42,60 L42,65 C42,65.5522847 41.5522847,66 41,66 L5,66 C4.44771525,66 4,65.5522847 4,65 L4,60 L1,60 C0.44771525,60 0,59.5522847 0,59 L0,1 C0,0.44771525 0.44771525,0 1,0 L45,0 Z M38,60 L8,60 L8,62 L38,62 L38,60 Z M42,23.641 L41.9,23.6470588 L4.1,23.6470588 L4,23.643 L4,56 L42,56 L42,23.641 Z M11,27.5882353 C12.0256717,27.5882353 12.8710143,28.3489616 12.9865445,29.3290113 L13,29.5588235 L13,51.2352941 C13,52.3236199 12.1045695,53.2058824 11,53.2058824 C9.97432832,53.2058824 9.12898568,52.4451561 9.01345546,51.4651063 L9,51.2352941 L9,29.5588235 C9,28.4704977 9.8954305,27.5882353 11,27.5882353 Z M42,4 L4,4 L4,19.711 L4.1,19.7058824 L41.9,19.7058824 L42,19.709 L42,4 Z M11,7.88235294 C12.0256717,7.88235294 12.8710143,8.6430792 12.9865445,9.62312896 L13,9.85294118 L13,13.7941176 C13,14.8824435 12.1045695,15.7647059 11,15.7647059 C9.97432832,15.7647059 9.12898568,15.0039796 9.01345546,14.0239299 L9,13.7941176 L9,9.85294118 C9,8.76461535 9.8954305,7.88235294 11,7.88235294 Z"
        />
      </g>
    </g>
  </svg>
</template>
