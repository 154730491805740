<template>
  <svg
    width="80px"
    height="80px"
    viewBox="0 0 80 80"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(17, 9)" id="Group-5">
        <path
          d="M45,0 C45.5522847,0 46,0.427364557 46,0.954545455 L46,56.3181818 C46,56.8453627 45.5522847,57.2727273 45,57.2727273 L42,57.272 L42,62.4 C42,62.8418278 41.5522847,63.2 41,63.2 L5,63.2 C4.44771525,63.2 4,62.8418278 4,62.4 L4,57.272 L1,57.2727273 C0.44771525,57.2727273 0,56.8453627 0,56.3181818 L0,0.954545455 C0,0.427364557 0.44771525,0 1,0 L45,0 Z M40,57.272 L6,57.272 L6,61.2 L40,61.2 L40,57.272 Z M43.9682286,21.9932723 L43.7,22 L2.3,22 C2.23099426,22 2.1626983,21.9986787 2.09523864,21.9960912 L2.0952381,55.1363636 L44.0952381,55.1363636 L44.0959013,21.9852412 C44.0537266,21.9884225 44.0111579,21.9911044 43.9682286,21.9932723 Z M10,27 C10.5128358,27 11,27.3433253 11,28.0902895 L11,50.0117819 C11,50.6313653 10.5522847,51.0002101 10,51.0002101 C9.48716416,51.0002101 9.04157564,50.6726177 9,50.0117819 L9,28.0902895 C9,27.375425 9.44771525,27 10,27 Z M44.0952381,2.13636364 L2.0952381,2.13636364 L2.095,20.005 L2.3,20 L43.7,20 C43.8348315,20 43.9669533,20.0050443 44.0954214,20.0147226 L44.0952381,2.13636364 Z M10,7 C10.5128358,7 10.9932723,7.30113742 10.9932723,8.05102359 L11,14.0099616 C11,14.6231451 10.5522847,15 10,15 C9.48716416,15 9.04234387,14.6310412 9.00672773,14.0177547 L9,8.04497517 C9.00672773,7.29966936 9.44771525,7 10,7 Z"
          fill="var(--v-secondary-base)"
        />
        <g transform="translate(19, 34)">
          <circle fill="var(--v-secondary-base)" cx="17" cy="17" r="17" />
          <g transform="translate(9, 10)" fill="#FFFFFF">
            <path
              d="M5.80788106,11.460334 L15.2376865,0.352833094 C15.5951182,-0.0681907138 16.226181,-0.119742327 16.6472048,0.237689343 C17.0682286,0.595121013 17.1197802,1.22618386 16.7623486,1.64720767 L5.81715402,14.5397068 L0.241598057,8.05178713 C-0.118362776,7.63292361 -0.0706127176,7.00156173 0.348250797,6.6416009 C0.767114311,6.28164007 1.39847619,6.32939012 1.75843702,6.74825364 L5.80788106,11.460334 Z"
              transform="translate(8.5, 7.2699) scale(-1, -1) rotate(-180) translate(-8.5, -7.2699)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
