<template>
  <svg
    width="80px"
    height="80px"
    viewBox="0 0 80 80"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g>
        <path
          d="M62,9 C62.5522847,9 63,9.42736456 63,9.95454545 L63,65.3181818 C63,65.8453627 62.5522847,66.2727273 62,66.2727273 L59,66.272 L59,71.4 C59,71.8418278 58.5522847,72.2 58,72.2 L22,72.2 C21.4477153,72.2 21,71.8418278 21,71.4 L21,66.272 L18,66.2727273 C17.4477153,66.2727273 17,65.8453627 17,65.3181818 L17,9.95454545 C17,9.42736456 17.4477153,9 18,9 L62,9 Z M57,66.272 L23,66.272 L23,70.2 L57,70.2 L57,66.272 Z M60.9682286,30.9932723 L60.7,31 L19.3,31 C19.2309943,31 19.1626983,30.9986787 19.0952386,30.9960912 L19.0952381,64.1363636 L61.0952381,64.1363636 L61.0959013,30.9852412 C61.0537266,30.9884225 61.0111579,30.9911044 60.9682286,30.9932723 Z M27,36 C27.5128358,36 28,36.3433253 28,37.0902895 L28,59.0117819 C28,59.6313653 27.5522847,60.0002101 27,60.0002101 C26.4871642,60.0002101 26.0415756,59.6726177 26,59.0117819 L26,37.0902895 C26,36.375425 26.4477153,36 27,36 Z M61.0952381,11.1363636 L19.0952381,11.1363636 L19.095,29.005 L19.3,29 L60.7,29 C60.8348315,29 60.9669533,29.0050443 61.0954214,29.0147226 L61.0952381,11.1363636 Z M27,16 C27.5128358,16 27.9932723,16.3011374 27.9932723,17.0510236 L28,23.0099616 C28,23.6231451 27.5522847,24 27,24 C26.4871642,24 26.0423439,23.6310412 26.0067277,23.0177547 L26,17.0449752 C26.0067277,16.2996694 26.4477153,16 27,16 Z"
          fill="var(--v-secondary-base)"
        />
        <circle fill="var(--v-secondary-base)" cx="61" cy="61" r="17" />
        <path
          d="M61.9587402,63.5512695 L61.9997559,62.9257812 C62.0749512,62.2558594 62.3723145,61.6713867 62.8918457,61.1723633 L62.8918457,61.1723633 L63.7224121,60.3828125 C64.3718262,59.7539062 64.826416,59.1813965 65.0861816,58.6652832 C65.3459473,58.1491699 65.4758301,57.6005859 65.4758301,57.0195312 C65.4758301,55.7412109 65.0759277,54.751709 64.276123,54.0510254 C63.4763184,53.3503418 62.3518066,53 60.9025879,53 C59.467041,53 58.3339844,53.3691406 57.503418,54.1074219 C56.6728516,54.8457031 56.2507324,55.8676758 56.2370605,57.1733398 L56.2370605,57.1733398 L59.2106934,57.1733398 C59.2243652,56.6264648 59.3833008,56.1975098 59.6875,55.8864746 C59.9916992,55.5754395 60.3967285,55.4199219 60.9025879,55.4199219 C61.9689941,55.4199219 62.5021973,55.9975586 62.5021973,57.152832 C62.5021973,57.5356445 62.3996582,57.8996582 62.1945801,58.244873 C61.989502,58.5900879 61.5776367,59.0378418 60.9589844,59.5881348 C60.340332,60.1384277 59.9147949,60.6972656 59.682373,61.2646484 C59.4499512,61.8320312 59.3337402,62.5942383 59.3337402,63.5512695 L59.3337402,63.5512695 L61.9587402,63.5512695 Z M60.6975098,68.2578125 C61.1965332,68.2578125 61.5981445,68.1108398 61.9023438,67.8168945 C62.206543,67.5229492 62.3586426,67.1503906 62.3586426,66.6992188 C62.3586426,66.2412109 62.203125,65.8635254 61.8920898,65.5661621 C61.5810547,65.2687988 61.1828613,65.1201172 60.6975098,65.1201172 C60.2121582,65.1201172 59.8139648,65.2687988 59.5029297,65.5661621 C59.1918945,65.8635254 59.036377,66.2412109 59.036377,66.6992188 C59.036377,67.1503906 59.1884766,67.5229492 59.4926758,67.8168945 C59.796875,68.1108398 60.1984863,68.2578125 60.6975098,68.2578125 Z"
          fill="#FFFFFF"
          fill-rule="nonzero"
        />
      </g>
    </g>
  </svg>
</template>
