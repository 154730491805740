<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z"
      fill="#E3A735"
    />
    <path d="M10.75 5.59961H9.25V11.3996H10.75V5.59961Z" fill="black" />
    <path d="M10.75 12.7996H9.25V14.3996H10.75V12.7996Z" fill="black" />
  </svg>
</template>
