<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>icons/24/ic_not_fully_onboarded</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(1.000000, 1.000000)" stroke="#000000">
        <polygon
          points="0.5 11 0.5 13.03 3.41 14.14 2.14 16.99 5.01 19.86 7.86 18.59 8.97 21.5 13.03 21.5 14.14 18.59 16.99 19.86 19.86 16.99 18.59 14.14 21.5 13.03 21.5 11 21.5 8.97 18.59 7.86 19.86 5.01 16.99 2.14 14.14 3.41 13.03 0.5 8.97 0.5 7.86 3.41 5.01 2.14 2.14 5.01 3.41 7.86 0.5 8.97"
        ></polygon>
        <circle cx="11" cy="11" r="4.4"></circle>
      </g>
      <circle fill="#FE655C" cx="18.5" cy="18.5" r="5.5"></circle>
      <path
        d="M18.5,21 C18.7761424,21 19,21.2238576 19,21.5 C19,21.7761424 18.7761424,22 18.5,22 C18.2238576,22 18,21.7761424 18,21.5 C18,21.2238576 18.2238576,21 18.5,21 Z M18.5,14 C18.7598987,14 18.9734829,14.2099605 18.9977111,14.4784259 L19,14.5294118 L19,19.4705882 C19,19.7629743 18.7761424,20 18.5,20 C18.2401013,20 18.0265171,19.7900395 18.0022889,19.5215741 L18,19.4705882 L18,14.5294118 C18,14.2370257 18.2238576,14 18.5,14 Z"
        fill="#FFFFFF"
        fill-rule="nonzero"
      ></path>
    </g>
  </svg>
</template>
