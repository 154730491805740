<template>
  <v-layout row justify-center>
    <v-dialog v-model="menuShown" fullscreen>
      <v-card class="pa-3" style="height: auto">
        <v-layout row>
          <v-card-title class="title">{{ $t('general.home.profile.title') }}</v-card-title>
          <v-spacer></v-spacer>
          <Cancel @evtCancel="closeMenu()" track="UserMenu" />
        </v-layout>
        <v-card-text style="height: auto">
          <v-list three-line subheader>
            <v-list-tile avatar class="unicolorList">
              <v-list-tile-content>
                <v-list-tile-title>{{ $t('general.menus.usermenu.account') }}</v-list-tile-title>
                <v-list-tile-sub-title>
                  {{ $t('general.menus.usermenu.accounttext') }}
                  <br />
                  {{ getCurrentUserName() }}
                </v-list-tile-sub-title>
              </v-list-tile-content>
              <v-btn class="ma-0" color="primary" depressed @click="logout()" v-track="'MobileUserMenu::Logout'">{{
                $t('general.home.profile.logout')
              }}</v-btn>
            </v-list-tile>
            <v-list-tile avatar v-if="showSessionRenewalCheck" class="unicolorList mt-4">
              <v-list-tile-content>
                <v-list-tile-title>{{ $t('general.home.profile.session') }}</v-list-tile-title>
                <v-list-tile-sub-title v-html="$t('general.home.profile.sessionrenew')"></v-list-tile-sub-title>
              </v-list-tile-content>
              <v-layout row>
                <v-switch
                  color="secondary"
                  class="justify-end"
                  v-model="sessionRenewal"
                  @change="toggleSessionRenewal()"
                ></v-switch>
              </v-layout>
            </v-list-tile>
            <MobileLanguageSetup :userSettings="userSettings" />
            <v-list-tile avatar class="unicolorList mt-4" v-if="$ppt.isLiebherr()">
              <v-list-tile-content>
                <v-list-tile-title>{{ $t('general.home.profile.optouttitle') }}</v-list-tile-title>
                <v-list-tile-sub-title v-html="$t('general.home.profile.optoutdescription')"></v-list-tile-sub-title>
              </v-list-tile-content>
              <v-btn
                depressed
                color="secondary"
                class="ma-0"
                @click="showCookieSettingsDialog()"
                outline
                target="_blank"
                v-track="'MobileUserMenu::ShowCookieDialog'"
                >{{ $t('general.home.profile.optoutbutton') }}</v-btn
              >
            </v-list-tile>
            <v-list-tile avatar class="unicolorList mt-4">
              <v-list-tile-content style="height: auto; padding-top: 50px">
                <v-list-tile-title>{{ $t('general.home.profile.settings') }}</v-list-tile-title>
                <v-list-tile-sub-title
                  v-if="isAdminUser()"
                  v-html="$t('general.home.profile.settingsdescadmin')"
                ></v-list-tile-sub-title>
                <v-list-tile-sub-title
                  v-else
                  v-html="$t('general.home.profile.settingsdescuser')"
                ></v-list-tile-sub-title>
              </v-list-tile-content>
              <v-btn
                depressed
                color="secondary"
                class="ma-0"
                :href="newPasswordLink"
                outline
                target="_blank"
                v-track="'MobileUserMenu::ChangePassword'"
              >
                <v-icon class="mr-2">{{ rightArrowIcon }}</v-icon>
                {{ $t('general.userprofile') }}
              </v-btn>
            </v-list-tile>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script lang="ts" src="./MobileUserMenu.ts"></script>
