<template>
  <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g>
      <g style transform="matrix(0.888889, 0, 0, 0.888889, 0, 0)">
        <path
          d="M 18 0 C 27.941 0 36 8.059 36 18 C 36 27.941 27.941 36 18 36 C 8.059 36 0 27.941 0 18 C 0 8.059 8.059 0 18 0 Z M 18 2 C 9.163 2 2 9.163 2 18 C 2 26.837 9.163 34 18 34 C 26.837 34 34 26.837 34 18 C 34 9.163 26.837 2 18 2 Z"
          id="Oval"
          fill-rule="nonzero"
          stroke="none"
          stroke-width="1"
          style="fill: var(--v-secondary-base)"
        />
        <g
          transform="matrix(1, 0, 0, 1, 14, 8.5)"
          style="stroke: var(--v-bus_white-base)"
          stroke-width="2"
          fill="none"
          fill-rule="evenodd"
        >
          <path
            d="M0,4.06451613 C0,-1.35483871 9,-1.35483871 9,4.06451613 C9,8.45893104 3.6,7 3.6,13"
            id="Path-48"
            stroke-linecap="round"
            style="stroke: var(--v-secondary-base)"
          />
          <rect
            x="3"
            y="18.5"
            width="1"
            height="1"
            rx="0.5"
            style="stroke: var(--v-secondary-base); fill: var(--v-bus_white-base)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
