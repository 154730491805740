<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-1082.000000, -336.000000)">
        <g transform="translate(1072.000000, 328.000000)">
          <g transform="translate(10.000000, 8.000000)">
            <rect x="0" y="0" width="24" height="24"></rect>
            <g transform="translate(1.500000, 3.500000)">
              <polyline stroke="#003259" points="13 10 13 14 7.38 14 3 17.5 3 14 0 14 0 5 7 5"></polyline>
              <polygon stroke="#003259" points="21 0 7 0 7 10 15 10 19 13.5 19 10 21 10"></polygon>
              <rect fill="#003259" fill-rule="nonzero" x="13.29" y="6.84" width="1.42" height="1.44"></rect>
              <path
                d="M14.05,1.64 C13.5787713,1.60615554 13.1150709,1.77229011 12.7725504,2.09768462 C12.4300299,2.42307913 12.2403497,2.87765608 12.25,3.35 L12.25,3.57 L13.44,3.57 L13.44,3.37 C13.430144,3.20797468 13.4923641,3.04987045 13.6100013,2.93801865 C13.7276386,2.82616684 13.8886771,2.77199234 14.05,2.79 C14.2202042,2.78286224 14.3849402,2.85091166 14.5004878,2.9760882 C14.6160354,3.10126474 14.6707095,3.27090971 14.65,3.44 L14.65,3.8 C14.6584829,3.97309971 14.5941278,4.1418486 14.4725346,4.26534173 C14.3509413,4.38883487 14.1832104,4.45579829 14.01,4.45 L13.4,4.45 L13.4,6.12 L14.59,6.12 L14.59,5.5 C15.3627648,5.30150759 15.8893326,4.58687993 15.85,3.79 L15.85,3.39 C15.861817,2.91354241 15.6742525,2.45377127 15.3325297,2.12154084 C14.990807,1.78931041 14.5259358,1.61476906 14.05,1.64 L14.05,1.64 Z"
                id="Path"
                fill="#003259"
                fill-rule="nonzero"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
