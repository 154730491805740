<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z"
      fill="#E52828"
    />
    <path
      d="M13.9016 7.19961L12.8016 6.09961L10.0016 8.89961L7.20156 6.09961L6.10156 7.19961L8.90156 9.99961L6.10156 12.7996L7.20156 13.8996L10.0016 11.0996L12.8016 13.8996L13.9016 12.7996L11.1016 9.99961L13.9016 7.19961Z"
      fill="white"
    />
  </svg>
</template>
