<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="36px"
    height="36px"
    viewBox="0 0 36 36"
    version="1.1"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="36" height="36" />
      <g id="Group" transform="translate(6, 1.5)" fill="#000000">
        <path
          d="M22.0108696,0 C22.2810088,0 22.5,0.223857625 22.5,0.5 L22.5,29.5 C22.5,29.7761424 22.2810088,30 22.0108696,30 L20.543,30 L20.5434783,32.5 C20.5434783,32.7761424 20.3244871,33 20.0543478,33 L2.44565217,33 C2.17551289,33 1.95652174,32.7761424 1.95652174,32.5 L1.956,30 L0.489130435,30 C0.218991155,30 0,29.7761424 0,29.5 L0,0.5 C0,0.223857625 0.218991155,0 0.489130435,0 L22.0108696,0 Z M18.586,30 L3.913,30 L3.91304348,31 L18.5869565,31 L18.586,30 Z M20.543,11.82 L20.4945652,11.8235294 L2.00543478,11.8235294 L1.956,11.82 L1.95652174,28 L20.5434783,28 L20.543,11.82 Z M5.38043478,13.7941176 C5.88212202,13.7941176 6.29560483,14.1744808 6.35211418,14.6645057 L6.35869565,14.7794118 L6.35869565,25.6176471 C6.35869565,26.16181 5.92071334,26.6029412 5.38043478,26.6029412 C4.87874755,26.6029412 4.46526473,26.222578 4.40875539,25.7325532 L4.40217391,25.6176471 L4.40217391,14.7794118 C4.40217391,14.2352488 4.84015622,13.7941176 5.38043478,13.7941176 Z M20.5434783,2 L1.95652174,2 L1.956,9.855 L2.00543478,9.85294118 L20.4945652,9.85294118 L20.543,9.855 L20.5434783,2 Z M5.38043478,3.94117647 C5.88212202,3.94117647 6.29560483,4.3215396 6.35211418,4.81156448 L6.35869565,4.92647059 L6.35869565,6.89705882 C6.35869565,7.44122174 5.92071334,7.88235294 5.38043478,7.88235294 C4.87874755,7.88235294 4.46526473,7.50198981 4.40875539,7.01196493 L4.40217391,6.89705882 L4.40217391,4.92647059 C4.40217391,4.38230767 4.84015622,3.94117647 5.38043478,3.94117647 Z"
        />
      </g>
    </g>
  </svg>
</template>
