<template>
  <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs></defs>
    <g transform="matrix(-2, 0, 0, 2, 32, 0)">
      <g
        transform="matrix(1, 0, 0, 1, 0, 4)"
        style="fill: var(--v-bus_white-base)"
        stroke="none"
        stroke-width="1"
        fill-rule="evenodd"
      >
        <path
          d="M16,0 L16,8 L2,8 L2,6 L0,6 L0,2 L2,2 L2,0 L16,0 Z M15,1 L3,1 L3,7 L15,7 L15,1 Z"
          style="fill: var(--v-bus_white-base)"
        ></path>
      </g>
    </g>
  </svg>
</template>
