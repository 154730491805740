<template>
  <svg
    width="18px"
    height="18px"
    viewBox="0 0 18 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <circle style="fill: var(--v-secondary-base)" cx="9" cy="9" r="9"></circle>
      <path
        d="M9.52941176,5.29411765 L9.52911765,8.47011765 L12.7058824,8.47058824 L12.7058824,9.52941176 L9.52911765,9.52911765 L9.52941176,12.7058824 L8.47058824,12.7058824 L8.47011765,9.52911765 L5.29411765,9.52941176 L5.29411765,8.47058824 L8.47011765,8.47011765 L8.47058824,5.29411765 L9.52941176,5.29411765 Z"
        style="fill: var(--v-bus_white-base)"
        fill-rule="nonzero"
        stroke="var(--v-bus_white-base)"
      ></path>
    </g>
  </svg>
</template>
