import { markRaw } from 'vue';

import PengUIfy from '@ldc_ulm/penguify';
import '@ldc_ulm/penguify/dist/penguify.min.css';

import CompanyThemeHandler from '@/CompanyThemeHandler';

import IconAlarm from '@/components/general/icons/alarm/IconAlarm.vue';
import IconWarning from '@/components/general/icons/general/IconWarning.vue';
import IconError from '@/components/general/icons/general/IconError.vue';
import IconNewAlarm from '@/components/general/icons/alarm/IconNewAlarm.vue';

// Dashboard/Navigation Bar
import IconNavigationMenu from '@/components/general/icons/navigation/IconMenu.vue';
import IconNavigationDashboard from '@/components/general/icons/navigation/IconDashboard.vue';
import IconNavigationAlarm from '@/components/general/icons/navigation/IconAlarm.vue';
import IconNavigationMessage from '@/components/general/icons/navigation/IconMessage.vue';
import IconNavigationReport from '@/components/general/icons/navigation/IconReport.vue';
import IconNavigationAppliance from '@/components/general/icons/navigation/IconAppliance.vue';
import IconNavigationEditor from '@/components/general/icons/navigation/IconEditor.vue';
import IconNavigationSettings from '@/components/general/icons/navigation/IconSettings.vue';
import IconNavigationHelp from '@/components/general/icons/navigation/IconHelp.vue';
import IconNavigationUser from '@/components/general/icons/navigation/IconUser.vue';

import IconLogger from '@/components/general/icons/IconLogger.vue';
import IconLoggerBlack from '@/components/general/icons/IconLoggerBlack.vue';

// Sensor
import IconSensorDoor from '@/components/general/icons/sensors/IconSensorDoor.vue';
import IconSensorDoorWhite from '@/components/general/icons/sensors/IconSensorDoorWhite.vue';
import IconSensorTemperature from '@/components/general/icons/sensors/IconSensorTemperature.vue';
import IconSensorTemperatureWhite from '@/components/general/icons/sensors/IconSensorTemperatureWhite.vue';
import IconSensorProductTemperature from '@/components/general/icons/sensors/IconSensorProductTemperature.vue';
import IconSensorProductTemperatureWhite from '@/components/general/icons/sensors/IconSensorProductTemperatureWhite.vue';
import IconSensorBadStrength from '@/components/general/icons/sensors/IconSensorBadStrength.vue';
import IconSensorGoodStrength from '@/components/general/icons/sensors/IconSensorGoodStrength.vue';
import IconSensorVeryGoodStrength from '@/components/general/icons/sensors/IconSensorVeryGoodStrength.vue';
import IconSensorBatteryGood from '@/components/general/icons/sensors/IconSensorBatteryGood.vue';
import IconSensorBatteryMedium from '@/components/general/icons/sensors/IconSensorBatteryMedium.vue';
import IconSensorBatteryLow from '@/components/general/icons/sensors/IconSensorBatteryLow.vue';
import IconSensorFlag from '@/components/general/icons/sensors/IconSensorFlag.vue';
// Alarm Temperature High and Low Icons - on and off
import IconAlarmHighTemperatureOn from '@/components/general/icons/alarm/IconAlarmHighTemperatureOn.vue';
import IconAlarmHighTemperatureOff from '@/components/general/icons/alarm/IconAlarmHighTemperatureOff.vue';
import IconAlarmLowTemperatureOn from '@/components/general/icons/alarm/IconAlarmLowTemperatureOn.vue';
import IconAlarmLowTemperatureOff from '@/components/general/icons/alarm/IconAlarmLowTemperatureOff.vue';

import IconAlarmHeartbeatOn from '@/components/general/icons/alarm/IconAlarmHeartbeatOn.vue';
import IconAlarmHeartbeatOff from '@/components/general/icons/alarm/IconAlarmHeartbeatOff.vue';

import IconAlarmHighCloudOn from '@/components/general/icons/alarm/IconAlarmHighCloudOn.vue';
import IconAlarmHighCloudOff from '@/components/general/icons/alarm/IconAlarmHighCloudOff.vue';
import IconAlarmLowCloudOn from '@/components/general/icons/alarm/IconAlarmLowCloudOn.vue';
import IconAlarmLowCloudOff from '@/components/general/icons/alarm/IconAlarmLowCloudOff.vue';

import IconAlarmDoorOn from '@/components/general/icons/alarm/IconAlarmDoorOn.vue';
import IconAlarmDoorOff from '@/components/general/icons/alarm/IconAlarmDoorOff.vue';

import IconAlarmPowerOn from '@/components/general/icons/alarm/IconAlarmPowerOn.vue';
import IconAlarmPowerOff from '@/components/general/icons/alarm/IconAlarmPowerOff.vue';

import IconAlarmDeviceOn from '@/components/general/icons/alarm/IconAlarmDeviceOn.vue';
import IconAlarmDeviceOff from '@/components/general/icons/alarm/IconAlarmDeviceOff.vue';

import IconAlarmBatteryOn from '@/components/general/icons/alarm/IconAlarmBatteryOn.vue';
import IconAlarmBatteryOff from '@/components/general/icons/alarm/IconAlarmBatteryOff.vue';

import IconAlarmBatteryPowerOn from '@/components/general/icons/alarm/IconAlarmBatteryPowerOn.vue';
import IconAlarmBatteryPowerOff from '@/components/general/icons/alarm/IconAlarmBatteryPowerOff.vue';

import IconAlarmBattery from '@/components/general/icons/alarm/IconAlarmBattery.vue';

// Icons are shown on the help screen
import IconHelpAlarmDevice from '@/components/general/icons/alarm/help/IconAlarmDevice.vue';
import IconHelpAlarmDoor from '@/components/general/icons/alarm/help/IconAlarmDoor.vue';
import IconHelpAlarmHeartbeat from '@/components/general/icons/alarm/help/IconAlarmHeartbeat.vue';
import IconHelpAlarmPower from '@/components/general/icons/alarm/help/IconAlarmPower.vue';
import IconHelpAlarmHighTemperature from '@/components/general/icons/alarm/help/IconAlarmHighTemperature.vue';
import IconHelpAlarmLowTemperature from '@/components/general/icons/alarm/help/IconAlarmLowTemperature.vue';
import IconHelpAlarmBattery from '@/components/general/icons/alarm/help/IconAlarmBattery.vue';
import IconHelpAlarmBatteryPower from '@/components/general/icons/alarm/help/IconAlarmBatteryPower.vue';
import IconHelpAlarmHighCloud from '@/components/general/icons/alarm/help/IconAlarmHighCloud.vue';
import IconHelpAlarmLowCloud from '@/components/general/icons/alarm/help/IconAlarmLowCloud.vue';

import IconSmartCoolingHub from '@/components/general/icons/IconSmartCoolingHub.vue';
import IconSmartCoolingHubWhite from '@/components/general/icons/IconSmartCoolingHubWhite.vue';
import IconService from '@/components/general/icons/IconService.vue';
import IconServiceRequest from '@/components/general/icons/IconServiceRequest.vue';
import IconServiceRequest2 from '@/components/general/icons/IconServiceRequest2.vue';
import IconServiceRequestWhite from '@/components/general/icons/general/IconServiceRequestWhite.vue';
import IconDoc from '@/components/general/icons/IconDoc.vue';
import IconFaq from '@/components/general/icons/IconFaq.vue';
import IconAdd from '@/components/general/icons/IconAdd.vue';
import IconMove from '@/components/general/icons/IconMove.vue';
import IconHelp from '@/components/general/icons/IconHelp.vue';
import IconStepperSelected from '@/components/general/icons/IconStepperSelected.vue';
import IconStepperUnselected from '@/components/general/icons/IconStepperUnselected.vue';

import IconStandIn from '@/components/general/icons/measuresites/IconStandIn.vue';
import IconStandInWhite from '@/components/general/icons/measuresites/IconStandInWhite.vue';

import IconDelete from '@/components/general/icons/IconDelete.vue';
import IconDeleteOutline from '@/components/general/icons/IconDeleteOutline.vue';
import IconNotFullyOnBoarded from '@/components/general/icons/IconNotFullyOnBoarded.vue';

import IconSend from '@/components/general/icons/IconSend.vue';
import IconChevronLeft from '@/components/general/icons/IconChevronLeft.vue';
import IconChevronRight from '@/components/general/icons/IconChevronRight.vue';

import IconExpand from '@/components/general/icons/IconExpand.vue';
import IconVideo from '@/components/general/icons/IconVideo.vue';
import IconEnvelope from '@/components/general/icons/IconEnvelope.vue';
import IconSave from '@/components/general/icons/IconSave.vue';
import IconExclamationTriangle from '@/components/general/icons/IconExclamationTriangle.vue';

import IconApplianceConnectGroup from '@/components/general/icons/IconApplianceConnectGroup.vue';

import IconAppliance from '@/components/general/icons/IconAppliance.vue';

//Banner appliance icons.
import IconApplianceWhite from '@/components/general/icons/IconApplianceWhite.vue';
import IconApplianceBlack from '@/components/general/icons/IconApplianceBlack.vue';

import IconApplianceBig from '@/components/general/icons/IconApplianceBig.vue';
import IconApplianceDisconnected from '@/components/general/icons/IconApplianceDisconnected.vue';
import IconDeviceOk from '@/components/general/icons/IconDeviceOk.vue';
import IconDeviceAssign from '@/components/general/icons/IconDeviceAssign.vue';
import IconDeviceReset from '@/components/general/icons/IconDeviceReset.vue';

import IconInternalActionLink from '@/components/general/icons/IconInternalActionLink.vue';
import IconActionLink from '@/components/general/icons/general/IconActionLink.vue';
import IconReload from '@/components/general/icons/general/IconReload.vue';

import IconComment from '@/components/general/icons/IconComment.vue';

import IconStatusOff from '@/components/general/icons/general/IconStatusOff.vue';
import IconStatusOn from '@/components/general/icons/general/IconStatusOn.vue';

import IconNetworkHigh from '@/components/general/icons/network/IconNetworkHigh.vue';
import IconNetworkMed from '@/components/general/icons/network/IconNetworkMed.vue';
import IconNetworkLow from '@/components/general/icons/network/IconNetworkLow.vue';

import IconUnlicensed from '@/components/general/icons/IconUnlicensed.vue';

import IconUser from '@/components/general/icons/IconUser.vue';
import IconUserBig from '@/components/general/icons/IconUserBig.vue';

import IconDefrost from '@/components/general/icons/IconDefrost.vue';

import IconLicense from '@/components/general/icons/IconLicense.vue';

import IconSettingsBig from '@/components/general/icons/IconSettingsBig.vue';
import IconCog from '@/components/general/icons/IconCog.vue';

import IconLink from '@/components/general/icons/IconLink.vue';

import IconPrint from '@/components/general/icons/IconPrint.vue';
import IconDownload from '@/components/general/icons/IconDownload.vue';
import IconClose from '@/components/general/icons/IconClose.vue';

import IconConnectivityOk from '@/components/general/icons/IconConnectivityOk.vue';
import IconConnectivityError from '@/components/general/icons/IconConnectivityError.vue';

import IconBarChart from '@/components/general/icons/IconBarChart.vue';
import IconPieChart from '@/components/general/icons/IconPieChart.vue';

//General icons
import IconMinus from '@/components/general/icons/IconMinus.vue';
import IconPlus from '@/components/general/icons/IconPlus.vue';
import IconMinusDisabled from '@/components/general/icons/IconMinusDisabled.vue';
import IconPlusDisabled from '@/components/general/icons/IconPlusDisabled.vue';
import IconApplianceDisabled from '@/components/general/icons/IconApplianceDisabled.vue';
import IconApplianceEnabled from '@/components/general/icons/IconApplianceEnabled.vue';
import IconDoorEnabled from '@/components/general/icons/IconDoorEnabled.vue';
import IconDoorDisabled from '@/components/general/icons/IconDoorDisabled.vue';
import IconTemperatureEnabled from '@/components/general/icons/IconTemperatureEnabled.vue';
import IconTemperatureDisabled from '@/components/general/icons/IconTemperatureDisabled.vue';
import IconConnectionErrorHelp from '@/components/general/icons/IconConnectionErrorHelp.vue';

// Data missing display
import IconContentMissing from '@/components/general/icons/datamissing/IconContentMissing.vue';
import IconHubMissing from '@/components/general/icons/datamissing/IconHubMissing.vue';
import IconStatusMissing from '@/components/general/icons/datamissing/IconStatusMissing.vue';
import IconNoContentSad from '@/components/general/icons/datamissing/IconNoContentSad.vue';
import IconEyeOpen from '@/components/general/icons/IconEyeOpen.vue';
import IconEyeClosed from '@/components/general/icons/IconEyeClosed.vue';

export default {
  install(app) {
    app.use(PengUIfy, {
      options: {
        customProperties: true
      },
      //All 'neutral' color definitions has new names start with 'steel' in corporative design page
      // https://liebherr.invisionapp.com/console/SmartMonitoring-1.0-ckenle8d120mb01x6axauw0ry/cl2rnersh012x01yd1m99g19m/play
      theme: CompanyThemeHandler.getCompanyTheme(),
      icons: {
        // Navigation Bar
        bus_navigation_menu: markRaw({
          component: IconNavigationMenu
        }),
        bus_navigation_dashboard: markRaw({
          component: IconNavigationDashboard
        }),
        bus_navigation_alarm: markRaw({
          component: IconNavigationAlarm
        }),
        bus_navigation_message: markRaw({
          component: IconNavigationMessage
        }),
        bus_navigation_report: markRaw({
          component: IconNavigationReport
        }),
        bus_navigation_appliance: markRaw({
          component: IconNavigationAppliance
        }),
        bus_navigation_editor: markRaw({
          component: IconNavigationEditor
        }),
        bus_navigation_settings: markRaw({
          component: IconNavigationSettings
        }),
        bus_navigation_help: markRaw({
          component: IconNavigationHelp
        }),
        bus_navigation_user: markRaw({
          component: IconNavigationUser
        }),

        // Use "bus" as prefix
        // Alarms, subfolder: alarm
        bus_alarm_high_temperature_on: markRaw({
          component: IconAlarmHighTemperatureOn
        }),
        bus_alarm_high_temperature_off: markRaw({
          component: IconAlarmHighTemperatureOff
        }),

        bus_alarm_low_temperature_on: markRaw({
          component: IconAlarmLowTemperatureOn
        }),
        bus_alarm_low_temperature_off: markRaw({
          component: IconAlarmLowTemperatureOff
        }),

        bus_alarm_heartbeat_on: markRaw({
          component: IconAlarmHeartbeatOn
        }),
        bus_alarm_heartbeat_off: markRaw({
          component: IconAlarmHeartbeatOff
        }),

        bus_alarm_battery_on: markRaw({
          component: IconAlarmBatteryOn
        }),
        bus_alarm_battery_off: markRaw({
          component: IconAlarmBatteryOff
        }),

        bus_alarm_battery_power_on: markRaw({
          component: IconAlarmBatteryPowerOn
        }),
        bus_alarm_battery_power_off: markRaw({
          component: IconAlarmBatteryPowerOff
        }),

        bus_alarm_low_cloud_on: markRaw({
          component: IconAlarmLowCloudOn
        }),
        bus_alarm_low_cloud_off: markRaw({
          component: IconAlarmLowCloudOff
        }),

        bus_alarm_high_cloud_on: markRaw({
          component: IconAlarmHighCloudOn
        }),
        bus_alarm_high_cloud_off: markRaw({
          component: IconAlarmHighCloudOff
        }),

        bus_alarm_door_on: markRaw({
          component: IconAlarmDoorOn
        }),
        bus_alarm_door_off: markRaw({
          component: IconAlarmDoorOff
        }),

        bus_alarm_device_on: markRaw({
          component: IconAlarmDeviceOn
        }),
        bus_alarm_device_off: markRaw({
          component: IconAlarmDeviceOff
        }),

        bus_alarm_power_on: markRaw({
          component: IconAlarmPowerOn
        }),
        bus_alarm_power_off: markRaw({
          component: IconAlarmPowerOff
        }),

        bus_alarm_new: markRaw({
          component: IconNewAlarm
        }),
        bus_alarm: markRaw({
          component: IconAlarm
        }),
        bus_warning: markRaw({
          component: IconWarning
        }),
        bus_error: markRaw({
          component: IconError
        }),

        // Sensor
        bus_sensor_door: markRaw({
          component: IconSensorDoor
        }),
        bus_sensor_door_white: markRaw({
          component: IconSensorDoorWhite
        }),
        bus_sensor_temperature: markRaw({
          component: IconSensorTemperature
        }),
        bus_sensor_temperature_white: markRaw({
          component: IconSensorTemperatureWhite
        }),
        bus_sensor_product_temperature: markRaw({
          component: IconSensorProductTemperature
        }),
        bus_sensor_product_temperature_white: markRaw({
          component: IconSensorProductTemperatureWhite
        }),
        bus_sensor_strength_bad: markRaw({
          component: IconSensorBadStrength
        }),
        bus_sensor_strength_good: markRaw({
          component: IconSensorGoodStrength
        }),
        bus_sensor_strength_verygood: markRaw({
          component: IconSensorVeryGoodStrength
        }),
        bus_sensor_battery_good: markRaw({
          component: IconSensorBatteryGood
        }),
        bus_sensor_battery_medium: markRaw({
          component: IconSensorBatteryMedium
        }),
        bus_sensor_battery_low: markRaw({
          component: IconSensorBatteryLow
        }),

        // Help icons
        bus_help_alarm_device: markRaw({
          component: IconHelpAlarmDevice
        }),
        bus_help_alarm_power: markRaw({
          component: IconHelpAlarmPower
        }),
        bus_help_alarm_door: markRaw({
          component: IconHelpAlarmDoor
        }),
        bus_help_alarm_heartbeat: markRaw({
          component: IconHelpAlarmHeartbeat
        }),
        bus_help_alarm_high_temperature: markRaw({
          component: IconHelpAlarmHighTemperature
        }),

        bus_help_alarm_low_temperature: markRaw({
          component: IconHelpAlarmLowTemperature
        }),
        bus_alarm_battery: markRaw({
          component: IconAlarmBattery
        }),
        bus_help_alarm_battery: markRaw({
          component: IconHelpAlarmBattery
        }),
        bus_help_alarm_battery_power: markRaw({
          component: IconHelpAlarmBatteryPower
        }),
        bus_help_alarm_high_cloud: markRaw({
          component: IconHelpAlarmHighCloud
        }),

        bus_help_alarm_low_cloud: markRaw({
          component: IconHelpAlarmLowCloud
        }),

        // Standard
        bus_add: markRaw({
          component: IconAdd
        }),
        bus_move: markRaw({
          component: IconMove
        }),
        bus_stepper_selected: markRaw({
          component: IconStepperSelected
        }),
        bus_stepper_unselected: markRaw({
          component: IconStepperUnselected
        }),
        // Measure sites
        bus_measuresite_standin: markRaw({
          component: IconStandIn
        }),
        bus_measuresite_standin_white: markRaw({
          component: IconStandInWhite
        }),
        bus_delete: markRaw({
          component: IconDelete
        }),

        bus_delete_outline: markRaw({
          component: IconDeleteOutline
        }),

        bus_send: markRaw({
          component: IconSend
        }),

        bus_chevron_left: markRaw({
          component: IconChevronLeft
        }),

        bus_chevron_right: markRaw({
          component: IconChevronRight
        }),

        bus_expand: markRaw({
          component: IconExpand
        }),

        bus_video: markRaw({
          component: IconVideo
        }),

        bus_envelope: markRaw({
          component: IconEnvelope
        }),

        bus_save: markRaw({
          component: IconSave
        }),

        bus_exclamation_triangle: markRaw({
          component: IconExclamationTriangle
        }),

        bus_not_fully_onboarded: markRaw({
          component: IconNotFullyOnBoarded
        }),

        bus_appgroup_connect: markRaw({
          component: IconApplianceConnectGroup
        }),

        bus_service: markRaw({
          component: IconService
        }),
        bus_service_request: markRaw({
          component: IconServiceRequest
        }),
        bus_doc: markRaw({
          component: IconDoc
        }),
        bus_faq: markRaw({
          component: IconFaq
        }),

        bus_sensor_flag: markRaw({
          component: IconSensorFlag
        }),

        bus_devicestate_all_ok: markRaw({
          component: IconDeviceOk
        }),
        bus_devicestate_assign_ok: markRaw({
          component: IconDeviceAssign
        }),
        bus_device_reset: markRaw({
          component: IconDeviceReset
        }),
        bus_unlicensed: markRaw({
          component: IconUnlicensed
        }),
        bus_plus: markRaw({
          component: IconPlus
        }),
        bus_minus: markRaw({
          component: IconMinus
        }),
        bus_plus_disabled: markRaw({
          component: IconPlusDisabled
        }),
        bus_minus_disabled: markRaw({
          component: IconMinusDisabled
        }),
        bus_appliance_disabled: markRaw({
          component: IconApplianceDisabled
        }),
        bus_appliance_enabled: markRaw({
          component: IconApplianceEnabled
        }),
        bus_door_disabled: markRaw({
          component: IconDoorDisabled
        }),
        bus_door_enabled: markRaw({
          component: IconDoorEnabled
        }),
        bus_temperature_disabled: markRaw({
          component: IconTemperatureDisabled
        }),
        bus_temperature_enabled: markRaw({
          component: IconTemperatureEnabled
        }),

        // Connectivity page icons
        bus_connectivity_ok: markRaw({
          component: IconConnectivityOk
        }),
        bus_connectivity_error: markRaw({
          component: IconConnectivityError
        }),

        // Sub folder: general
        bus_general_gateway: markRaw({
          component: IconSmartCoolingHub
        }),
        bus_general_gateway_white: markRaw({
          component: IconSmartCoolingHubWhite
        }),
        bus_general_internal_link: markRaw({
          component: IconInternalActionLink
        }),
        bus_general_link: markRaw({
          component: IconActionLink
        }),
        bus_general_reload: markRaw({
          component: IconReload
        }),

        bus_general_status_on: markRaw({
          component: IconStatusOn
        }),
        bus_general_status_off: markRaw({
          component: IconStatusOff
        }),

        bus_comment: markRaw({
          component: IconComment
        }),

        bus_network_high: markRaw({
          component: IconNetworkHigh
        }),
        bus_network_med: markRaw({
          component: IconNetworkMed
        }),
        bus_network_low: markRaw({
          component: IconNetworkLow
        }),

        bus_appliance: markRaw({
          component: IconAppliance
        }),
        bus_appliance_disconnected: markRaw({
          component: IconApplianceDisconnected
        }),
        bus_appliance_white: markRaw({
          component: IconApplianceWhite
        }),
        bus_appliance_black: markRaw({
          component: IconApplianceBlack
        }),
        bus_appliance_big: markRaw({
          component: IconApplianceBig
        }),

        bus_user: markRaw({
          component: IconUser
        }),
        bus_user_big: markRaw({
          component: IconUserBig
        }),
        bus_defrost: markRaw({
          component: IconDefrost
        }),
        bus_license: markRaw({
          component: IconLicense
        }),
        bus_settings_big: markRaw({
          component: IconSettingsBig
        }),

        bus_cog: markRaw({
          component: IconCog
        }),
        bus_link: markRaw({
          component: IconLink
        }),
        bus_print: markRaw({
          component: IconPrint
        }),
        bus_download: markRaw({
          component: IconDownload
        }),
        bus_close: markRaw({
          component: IconClose
        }),

        bus_chart_bar: markRaw({
          component: IconBarChart
        }),
        bus_chart_pie: markRaw({
          component: IconPieChart
        }),

        // Data missing display
        bus_datamissing_content: markRaw({
          component: IconContentMissing
        }),
        bus_datamissing_hub: markRaw({
          component: IconHubMissing
        }),
        bus_datamissing_status: markRaw({
          component: IconStatusMissing
        }),
        bus_datamissing_no_content: markRaw({
          component: IconNoContentSad
        }),
        bus_connection_error_help: markRaw({
          component: IconConnectionErrorHelp
        }),
        bus_service_request_2: markRaw({
          component: IconServiceRequest2
        }),
        bus_service_request_white: markRaw({
          component: IconServiceRequestWhite
        }),

        bus_help_icon: markRaw({
          component: IconHelp
        }),

        bus_eye_open: markRaw({
          component: IconEyeOpen
        }),

        bus_eye_closed: markRaw({
          component: IconEyeClosed
        }),
        bus_logger: markRaw({
          component: IconLogger
        }),
        bus_logger_black: markRaw({
          component: IconLoggerBlack
        })
      }
    });
  }
};
