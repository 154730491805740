<template>
  <svg
    width="90px"
    height="90px"
    viewBox="0 0 60 60"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-192.000000, -217.000000)" style="fill: var(--v-neutral40-base)">
        <g transform="translate(157.000000, 183.000000)">
          <g transform="translate(24.000000, 24.000000)">
            <path
              d="M43.9983448,15.1779521 L48.704921,9.96506931 C51.5820952,10.700965 54.2981358,11.8393946 56.7894969,13.3168122 L56.4312237,20.3294402 C58.0079534,21.5680832 59.432069,22.9922115 60.6706982,24.5689525 L67.6827844,24.2098229 C69.1603982,26.7013698 70.2989681,29.4176429 71.0349307,32.295079 L65.8220479,37.0016552 C65.9395476,37.984714 66,38.9852973 66,40 C66,41.015047 65.9395066,42.0159651 65.8219283,42.9993456 L71.0349307,47.704921 C70.2989681,50.5823571 69.1603982,53.2986302 67.6827844,55.7901771 L60.6706982,55.4310475 C59.432069,57.0077885 58.0079534,58.4319168 56.4312237,59.6705598 L56.7894969,66.6831878 C54.2978474,68.1607765 51.5814663,69.2992987 48.7039216,70.0351863 L43.9973441,64.8221675 C43.014607,64.9395886 42.0143584,65 41,65 C39.9852973,65 38.984714,64.9395476 38.0016552,64.8220479 L33.295079,70.0349307 C30.4182887,69.2991332 27.7025891,68.1609093 25.2115005,66.6837793 L25.5697806,59.6713487 C23.9926513,58.4325215 22.5681939,57.0081233 21.3293018,55.4310475 L14.3172156,55.7901771 C12.8396018,53.2986302 11.7010319,50.5823571 10.9650693,47.704921 L16.1779521,42.9983448 C16.0604524,42.015286 16,41.0147027 16,40 C16,38.9856416 16.0604114,37.985393 16.1778325,37.0026559 L10.9648137,32.2960784 C11.7007683,29.4182719 12.8394307,26.7016582 14.3172156,24.2098229 L21.3293018,24.5689525 C22.5681939,22.9918767 23.9926513,21.5674785 25.5697806,20.3286513 L25.2115005,13.3162207 C27.7023006,11.8392618 30.4176598,10.7011304 33.2940796,9.96532494 L38.0006544,15.1780717 C38.9840349,15.0604934 39.984953,15 41,15 C42.0147027,15 43.015286,15.0604524 43.9983448,15.1779521 L48.704921,9.96506931 Z M41,24 C32.163444,24 25,31.163444 25,40 C25,48.836556 32.163444,56 41,56 C49.836556,56 57,48.836556 57,40 C57,31.163444 49.836556,24 41,24 Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
