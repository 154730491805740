<template>
  <svg
    width="81px"
    height="80px"
    viewBox="0 0 81 80"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-920.000000, -482.000000)" fill="var(--v-secondary-base)">
        <g transform="translate(919.000000, 482.000000)">
          <g transform="translate(0.500000, 0.000000)">
            <path
              d="M30.6016949,14 L36.5338983,19.8888889 L69.7542373,19.8888889 C72.3752497,19.8888889 74.5,21.9981252 74.5,24.6 L74.5,62.2888889 C74.5,64.8907637 72.3752497,67 69.7542373,67 L9.24576271,67 C6.62475034,67 4.5,64.8907637 4.5,62.2888889 L4.5,18.7111111 C4.5,16.1092363 6.62475034,14 9.24576271,14 L30.6016949,14 Z M29.6188153,16.3555556 L9.24576271,16.3555556 C7.93525653,16.3555556 6.87288136,17.4101737 6.87288136,18.7111111 L6.87288136,62.2888889 C6.87288136,63.5898263 7.93525653,64.6444444 9.24576271,64.6444444 L69.7542373,64.6444444 C71.0647435,64.6444444 72.1271186,63.5898263 72.1271186,62.2888889 L72.1271186,24.6 C72.1271186,23.2990626 71.0647435,22.2444444 69.7542373,22.2444444 L35.5510187,22.2444444 L29.6188153,16.3555556 Z M40.6386003,46.3888889 C43.4311864,46.3888889 46.1422607,47.2445777 48.7506077,48.9362205 C49.2991668,49.2919883 49.4533336,50.0218432 49.094949,50.5663969 C48.7365644,51.1109506 48.0013412,51.2639918 47.4527821,50.908224 C45.2184155,49.459126 42.9540935,48.7444444 40.6386003,48.7444444 C38.3049891,48.7444444 35.8512738,49.4705 33.2690684,50.9434187 C32.7009289,51.2674919 31.9757152,51.0729998 31.6492584,50.5090086 C31.3228016,49.9450173 31.5187242,49.2250989 32.0868638,48.9010257 C35.0107583,47.2332037 37.8641324,46.3888889 40.6386003,46.3888889 Z M32.3813559,37.5555556 C33.3642356,37.5555556 34.1610169,38.3465192 34.1610169,39.3222222 C34.1610169,40.2979253 33.3642356,41.0888889 32.3813559,41.0888889 C31.3984763,41.0888889 30.6016949,40.2979253 30.6016949,39.3222222 C30.6016949,38.3465192 31.3984763,37.5555556 32.3813559,37.5555556 Z M47.8050847,37.5555556 C48.7879644,37.5555556 49.5847458,38.3465192 49.5847458,39.3222222 C49.5847458,40.2979253 48.7879644,41.0888889 47.8050847,41.0888889 C46.8222051,41.0888889 46.0254237,40.2979253 46.0254237,39.3222222 C46.0254237,38.3465192 46.8222051,37.5555556 47.8050847,37.5555556 Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
