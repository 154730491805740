<template>
  <div id="mobile-app">
    <v-app>
      <v-content>
        <SupportedBrowsersDialog v-if="unsupportedBrowser" :dialogShown="unsupportedBrowser"></SupportedBrowsersDialog>
        <MobileMaintenanceDialog
          v-else-if="maintenance"
          :dialogShown="maintenance"
          :data="maintenanceObject"
        ></MobileMaintenanceDialog>
        <router-view v-else></router-view>
      </v-content>
    </v-app>
  </div>
</template>

<script lang="ts" src="./MobileApp.ts"></script>
