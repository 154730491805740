<template>
  <svg viewBox="0 0 74 74" width="74px" height="74px" xmlns="http://www.w3.org/2000/svg">
    <g
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      transform="matrix(2.617989, 0, 0, 2.617989, -4.887825, -4.887825)"
    >
      <path
        d="M16,5A11,11,0,1,0,27,16,11,11,0,0,0,16,5Zm0,20a9,9,0,1,1,9-9A9,9,0,0,1,16,25Z"
        style="fill: var(--v-secondary-base)"
      />
      <polygon points="15 15 9.33 15 9.33 17 17 17 17 8.83 15 8.83 15 15" style="fill: var(--v-secondary-base)" />
    </g>
  </svg>
</template>
