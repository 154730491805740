<template>
  <v-btn
    depressed
    v-if="icon"
    flat
    @click="onCancelPressed()"
    :disabled="cancelDisabled"
    icon
    v-track="track + '::Cancel'"
    style="border-radius: 40px"
  >
    <v-icon>$vuetify.icons.bus_close</v-icon>
  </v-btn>
  <v-btn
    depressed
    v-else
    :style="buttonBorder"
    :color="buttonColor"
    class="secondary"
    outline
    @click="onCancelPressed()"
    :disabled="cancelDisabled"
    v-track="track + '::Cancel'"
    >{{ buttonText }}</v-btn
  >
</template>

<script lang="ts" src="./Cancel.ts"></script>
