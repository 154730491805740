<template>
  <svg width="24px" height="24px" viewBox="4 4 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(1.000000, 5.000000)" style="fill: var(--v-bus_light_grey-base)" fill-rule="nonzero">
        <path
          d="M11.8330952,4.4 L13.2,5.76690476 L11.266,7.7 L13.2,9.63309524 L11.8330952,11 L9.9,9.066 L7.96690476,11 L6.6,9.63309524 L8.533,7.7 L6.6,5.76690476 L7.96690476,4.4 L9.9,6.333 L11.8330952,4.4 Z"
        />
        <path
          d="M17.2948229,5.05112903 C16.8607119,3.18290323 15.192812,1.82209677 13.2050408,1.82209677 C12.9308655,1.82209677 12.6795381,1.84516129 12.4053628,1.89129032 C11.3772053,0.691935484 9.8920889,0 8.2927328,0 C5.29965209,0 2.87776999,2.44483871 2.85492204,5.46629032 C1.20987005,6.20435484 0.113168724,7.84193548 0.113168724,9.71016129 C0.113168724,12.2241935 2.16948371,14.3 4.70560554,14.3 L17.249127,14.3 C19.7852488,14.3 21.8415638,12.2241935 21.8415638,9.66403226 C21.8187158,7.15 19.7852488,5.07419355 17.2948229,5.05112903 Z M17.226279,12.5932258 L4.70560554,12.5932258 C3.10624943,12.5932258 1.82676455,11.2785484 1.82676455,9.68709677 C1.82676455,8.37241935 2.71783438,7.21919355 3.97447132,6.87322581 C4.38573431,6.75790323 4.65990965,6.36580645 4.61421376,5.92758065 C4.59136581,5.74306452 4.59136581,5.60467742 4.59136581,5.46629032 C4.59136581,3.39048387 6.25926575,1.70677419 8.31558074,1.70677419 C9.54936973,1.70677419 10.7146149,2.32951613 11.4000532,3.36741935 C11.6285327,3.69032258 12.0397957,3.82870968 12.4053628,3.69032258 C12.6795381,3.59806452 12.9537134,3.52887097 13.2507367,3.52887097 C14.6216134,3.52887097 15.6954668,4.61290323 15.7411626,5.97370968 C15.7411626,6.22741935 15.8554024,6.45806452 16.0610339,6.61951613 C16.2666654,6.78096774 16.5179928,6.85016129 16.7464722,6.80403226 C18.5286119,6.48112903 20.10512,7.91112903 20.10512,9.68709677 C20.10512,11.2785484 18.8256351,12.5932258 17.226279,12.5932258 Z"
        />
      </g>
    </g>
  </svg>
</template>
