<template>
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g>
        <g style="fill: var(--v-bus_yellow-base)">
          <circle cx="16" cy="16" r="16" />
        </g>
        <g transform="translate(4.000000, 4.000000)" style="fill: var(--v-bus_black-base)">
          <g transform="translate(3.000000, 8.000000)">
            <path d="M18,0 L18,9 L2,9 L2,7 L0,7 L0,2 L2,2 L2,0 L18,0 Z M4,2 L4,7 L11,7 L11,2 L4,2 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
