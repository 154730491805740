<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
  >
    <title>ic_user</title>
    <g id="ic_user" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Atom/Icon/24/User">
        <rect id="Rectangle" x="0" y="0" width="24" height="24" />
        <rect id="Rectangle" x="0" y="0" width="24" height="24" />
        <g id="liebherr-user-icon-rgb-24px" transform="translate(4.000000, 2.000000)" stroke="#000000">
          <path
            d="M15.5000335,20.5 L0.499934254,20.5 L0.499934254,16.32 C0.499934254,15.76 0.4,11.5 8,11.5 C15.6,11.5 15.5000335,15.76 15.5000335,16.32 L15.5000335,20.5 Z"
            id="Path"
          />
          <path
            d="M4.5,6.54 L4.5,3.47 C4.5,-0.48 11.5,-0.53 11.5,3.47 L11.5,6.54 C11.5,10.49 4.5,10.49 4.5,6.54 Z"
            id="Path"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
