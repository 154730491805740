<template>
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g>
        <g style="fill: var(--v-bus_yellow-base)">
          <circle cx="16" cy="16" r="16" />
        </g>
        <g transform="scale(0.55,0.55)" style="fill: var(--v-bus_black-base)">
          <g transform="translate(18.000000, 10.000000)">
            <path
              d="M21,36 L0,36 L0,3.81122449 L21,3.81122449 L21,36 Z M1.44827586,34.5306122 L19.5517241,34.5306122 L19.5517241,5.28061224 L1.44827586,5.28061224 L1.44827586,34.5306122 Z M16.6551724,4.54591837 L15.2068966,4.54591837 L15.2068966,1.46938776 L5.79310345,1.46938776 L5.79310345,4.54591837 L4.34482759,4.54591837 L4.34482759,0 L16.6551724,0 L16.6551724,4.54591837 Z M8.68965517,30.3979592 L7.46767241,29.6632653 L12.5366379,20.9387755 L5.88362069,20.9387755 L12.4008621,10.0102041 L13.6228448,10.744898 L8.41810345,19.4693878 L15.1616379,19.4693878 L8.68965517,30.3979592 Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
