<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="80px"
    height="80px"
    viewBox="0 0 80 80"
    version="1.1"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(5.000000, 5.000000)" style="fill: var(--v-neutral40-base)" fill-rule="nonzero">
        <path
          d="M35,0 C15.6947368,0 0,15.6947368 0,35 C0,54.3052632 15.6947368,70 35,70 C54.3052632,70 70,54.3052632 70,35 C70,15.6947368 54.3052632,0 35,0 Z M53.0526316,59.6842105 L53.0526316,56.8840877 C53.0526316,49.8840877 49.0736228,43.7682982 43.1789474,40.8209298 C40.8211754,42.294614 38.0210526,43.1787018 35,43.1787018 C31.9789474,43.1787018 29.178886,42.294614 26.8210526,40.8209298 C25.126193,41.7050175 23.578886,42.8102807 22.2527544,44.1367193 C19.0105877,47.378886 16.947614,51.9472456 16.947614,56.8840877 L16.947614,59.6842105 C9.35814035,54.0842719 4.42129825,45.1684211 4.42129825,35 C4.42129825,18.1263158 18.1265614,4.42105263 35.0002456,4.42105263 C51.8739298,4.42105263 65.579193,18.1263158 65.579193,35 C65.579193,45.0947368 60.6424737,54.0842105 53.0528772,59.6842105 L53.0526316,59.6842105 Z"
          id="Shape"
        />
        <path
          d="M35,39 C28.9249444,39 24,34.0750556 24,28 C24,21.9249444 28.9249444,17 35,17 C41.0750556,17 46,21.9249444 46,28 C46,34.0750556 41.0750556,39 35,39 Z"
          id="Path"
        />
      </g>
    </g>
  </svg>
</template>
