<template>
  <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      transform="matrix(0.924004, 0, 0, 0.924004, -0.632072, -1.264148)"
    >
      <path
        d="M16.3935744,1.83446956 C17.3742707,1.21267333 18.6257293,1.21267333 19.6064256,1.83446956 L19.6064256,1.83446956 L32.6064256,10.07693 C33.4741758,10.6271143 34,11.5831136 34,12.6105829 L34,12.6105829 L34,33 C34,34.6568542 32.6568542,36 31,36 L31,36 L5,36 C3.34314575,36 2,34.6568542 2,33 L2,33 L2,12.6105829 C2,11.5831136 2.52582424,10.6271143 3.39357436,10.07693 L3.39357436,10.07693 Z M18.5354752,3.52357145 C18.2085764,3.31630604 17.7914236,3.31630604 17.4645248,3.52357145 L17.4645248,3.52357145 L4.46452479,11.7660319 C4.17527475,11.9494267 4,12.2680931 4,12.6105829 L4,12.6105829 L4,33 C4,33.5522847 4.44771525,34 5,34 L5,34 L16.5,33.9991224 L16.5,23.5 C16.5,21.9023191 17.74892,20.5963391 19.3237272,20.5050927 L19.5,20.5 L24.5,20.5 C26.0976809,20.5 27.4036609,21.74892 27.4949073,23.3237272 L27.5,23.5 L27.5,33.9991224 L31,34 C31.5128358,34 31.9355072,33.6139598 31.9932723,33.1166211 L32,33 L32,12.6105829 C32,12.2680931 31.8247253,11.9494267 31.5354752,11.7660319 L31.5354752,11.7660319 Z M24.5,22.5 L19.5,22.5 C18.9871642,22.5 18.5644928,22.8860402 18.5067277,23.3833789 L18.5,23.5 L18.5,33.9991224 L25.5,33.9991224 L25.5,23.5 C25.5,22.9871642 25.1139598,22.5644928 24.6166211,22.5067277 L24.5,22.5 Z"
        style="fill: var(--v-secondary-base)"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>
