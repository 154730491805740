<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="36px"
    height="36px"
    viewBox="0 0 36 36"
    version="1.1"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(5.000000, 0.000000)" fill="#FFFFFF">
        <path
          d="M25.4347826,0 C25.7469436,0 26,0.244208318 26,0.545454545 L26,32.1818182 C26,32.4830644 25.7469436,32.7272727 25.4347826,32.7272727 L23.739,32.727 L23.7391304,35.4545455 C23.7391304,35.7557917 23.486074,36 23.173913,36 L2.82608696,36 C2.51392601,36 2.26086957,35.7557917 2.26086957,35.4545455 L2.26,32.727 L0.565217391,32.7272727 C0.253056446,32.7272727 0,32.4830644 0,32.1818182 L0,0.545454545 C0,0.244208318 0.253056446,0 0.565217391,0 L25.4347826,0 Z M21.4782609,32.7272727 L4.52173913,32.7272727 L4.52173913,33.8181818 L21.4782609,33.8181818 L21.4782609,32.7272727 Z M23.739,12.895 L23.6826087,12.8983957 L2.3173913,12.8983957 L2.26,12.895 L2.26086957,30.5454545 L23.7391304,30.5454545 L23.739,12.895 Z M6.2173913,15.0481283 C6.79711877,15.0481283 7.27492114,15.4630699 7.34022083,15.9976425 L7.34782609,16.1229947 L7.34782609,27.9465241 C7.34782609,28.5401563 6.8417132,29.0213904 6.2173913,29.0213904 C5.63766383,29.0213904 5.15986147,28.6064488 5.09456178,28.0718762 L5.08695652,27.9465241 L5.08695652,16.1229947 C5.08695652,15.5293624 5.59306941,15.0481283 6.2173913,15.0481283 Z M23.7391304,2.18181818 L2.26086957,2.18181818 L2.26,10.751 L2.3173913,10.7486631 L23.6826087,10.7486631 L23.739,10.751 L23.7391304,2.18181818 Z M6.2173913,4.29946524 C6.79711877,4.29946524 7.27492114,4.71440684 7.34022083,5.24897943 L7.34782609,5.37433155 L7.34782609,7.52406417 C7.34782609,8.11769644 6.8417132,8.59893048 6.2173913,8.59893048 C5.63766383,8.59893048 5.15986147,8.18398889 5.09456178,7.64941629 L5.08695652,7.52406417 L5.08695652,5.37433155 C5.08695652,4.78069928 5.59306941,4.29946524 6.2173913,4.29946524 Z"
          style="fill: var(--v-secondary-base)"
        />
      </g>
    </g>
  </svg>
</template>
