<template>
  <svg
    width="80px"
    height="80px"
    viewBox="0 0 80 80"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g fill="var(--v-secondary-base)">
        <path
          d="M62,9 C62.5522847,9 63,9.42736456 63,9.95454545 L63,65.3181818 C63,65.8453627 62.5522847,66.2727273 62,66.2727273 L59,66.272 L59,71.4 C59,71.8418278 58.5522847,72.2 58,72.2 L22,72.2 C21.4477153,72.2 21,71.8418278 21,71.4 L21,66.272 L18,66.2727273 C17.4477153,66.2727273 17,65.8453627 17,65.3181818 L17,9.95454545 C17,9.42736456 17.4477153,9 18,9 L62,9 Z M57,66.272 L23,66.272 L23,70.2 L57,70.2 L57,66.272 Z M60.9682286,30.9932723 L60.7,31 L19.3,31 C19.2309943,31 19.1626983,30.9986787 19.0952386,30.9960912 L19.0952381,64.1363636 L61.0952381,64.1363636 L61.0959013,30.9852412 C61.0537266,30.9884225 61.0111579,30.9911044 60.9682286,30.9932723 Z M27,36 C27.5128358,36 28,36.3433253 28,37.0902895 L28,59.0117819 C28,59.6313653 27.5522847,60.0002101 27,60.0002101 C26.4871642,60.0002101 26.0415756,59.6726177 26,59.0117819 L26,37.0902895 C26,36.375425 26.4477153,36 27,36 Z M61.0952381,11.1363636 L19.0952381,11.1363636 L19.095,29.005 L19.3,29 L60.7,29 C60.8348315,29 60.9669533,29.0050443 61.0954214,29.0147226 L61.0952381,11.1363636 Z M27,16 C27.5128358,16 27.9932723,16.3011374 27.9932723,17.0510236 L28,23.0099616 C28,23.6231451 27.5522847,24 27,24 C26.4871642,24 26.0423439,23.6310412 26.0067277,23.0177547 L26,17.0449752 C26.0067277,16.2996694 26.4477153,16 27,16 Z"
        />
      </g>
    </g>
  </svg>
</template>
