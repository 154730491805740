<template>
  <v-dialog v-model="dialogShown" max-width="800px" persistent>
    <BaseWindowItem :dialogShown="dialogShown" :buttonExit="false" :maxWidth="'800px'">
      <template v-slot:title>
        <v-flex>
          <v-layout column pa-3>
            {{ $t('general.home.supportedbrowsers.title') }}
          </v-layout>
        </v-flex>
      </template>
      <template v-slot:description>
        <v-flex>
          <v-layout column pl-3>
            {{ $t('general.home.supportedbrowsers.description') }}
          </v-layout>
        </v-flex>
      </template>
      <template v-slot:content>
        <v-layout mt-5 row wrap style="width: 100%">
          <v-flex xs12 md3>
            <v-layout column align-center>
              <a v-if="MobileHelper.isMobile" :href="getStoreUrl('google-chrome')">
                <v-img :src="AssetHelper.chrome" class="browser-img" contain></v-img>
              </a>
              <v-img v-else :src="AssetHelper.chrome" class="browser-img" contain></v-img>
              <p class="browser-name">{{ $t('general.home.supportedbrowsers.chrome') }}</p>
              <v-btn
                v-if="!MobileHelper.isMobile"
                :href="getStoreUrl('google-chrome')"
                depressed
                outline
                color="primary"
                v-track="'SupportBrowserDialog::DownloadChrome'"
                >{{ $t('general.download') }}</v-btn
              >
            </v-layout>
          </v-flex>
          <v-flex xs12 md3>
            <v-layout column align-center>
              <a v-if="MobileHelper.isMobile" :href="getStoreUrl('firefox-private-safe-browser')">
                <v-img :src="AssetHelper.firefox" class="browser-img" contain></v-img>
              </a>
              <v-img v-else :src="AssetHelper.firefox" class="browser-img" contain></v-img>
              <p class="browser-name">{{ $t('general.home.supportedbrowsers.firefox') }}</p>
              <v-btn
                v-if="!MobileHelper.isMobile"
                :href="getStoreUrl('firefox-private-safe-browser')"
                depressed
                outline
                color="primary"
                v-track="'SupportBrowserDialog::DownloadMozilla'"
                >{{ $t('general.download') }}</v-btn
              >
            </v-layout>
          </v-flex>
          <v-flex xs12 md3>
            <v-layout column align-center>
              <a v-if="MobileHelper.isMobile" :href="getStoreUrl('microsoft-edge-browse-with-ai')">
                <v-img :src="AssetHelper.edge" class="browser-img" contain></v-img>
              </a>
              <v-img v-else :src="AssetHelper.edge" class="browser-img" contain></v-img>
              <p class="browser-name">{{ $t('general.home.supportedbrowsers.edge') }}</p>
              <v-btn
                v-if="!MobileHelper.isMobile"
                :href="getStoreUrl('microsoft-edge-browse-with-ai')"
                depressed
                outline
                color="primary"
                v-track="'SupportBrowserDialog::DownloadMSEdge'"
                >{{ $t('general.download') }}</v-btn
              >
            </v-layout>
          </v-flex>

          <v-flex xs12 md3>
            <v-layout column align-center>
              <a v-if="isSafariDisabled()">
                <v-img
                  style="filter: grayscale(100%); opacity: 0.5"
                  :src="AssetHelper.safari"
                  class="browser-img"
                  contain
                ></v-img>
              </a>
              <a v-else>
                <v-img :src="AssetHelper.safari" class="browser-img" contain></v-img>
              </a>
              <p v-if="isSafariDisabled()" style="filter: grayscale(100%); opacity: 0.1" class="browser-name">
                {{ $t('general.home.supportedbrowsers.safari') }} *
              </p>
              <p v-else class="browser-name">{{ $t('general.home.supportedbrowsers.safari') }} *</p>
              <v-btn
                v-if="!MobileHelper.isMobile"
                disabled
                :href="getStoreUrl('microsoft-edge-browse-with-ai')"
                depressed
                outline
                color="primary"
                v-track="'SupportBrowserDialog::DownloadMSEdge'"
                >{{ $t('general.download') }} *</v-btn
              >
            </v-layout>
          </v-flex>
        </v-layout>
        <v-flex v-if="!isSafariDisabled()" pa-3> * {{ $t('general.home.supportedbrowsers.info') }} </v-flex>
        <v-flex v-else pa-3> * {{ $t('general.home.supportedbrowsers.additional') }} </v-flex>
      </template>
    </BaseWindowItem>
  </v-dialog>
</template>

<script lang="ts" src="./SupportedBrowsersDialog.ts"></script>
<style scoped src="./SupportedBrowsersDialog.css"></style>
