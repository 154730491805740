<template>
  <v-dialog v-model="dialogShown" persistent fullscreen>
    <v-card>
      <v-container style="display: flex; min-height: 100vh">
        <v-layout align-center justify-center column>
          <v-flex shrink class="justify-center align-center">
            <v-img :src="AssetHelper.company_logo_dark" class="bus_logo_mobile" contain></v-img>
            <h1 style="text-align: center; color: secondary">{{ maintenanceTitle }}</h1>
          </v-flex>
          <v-flex shrink class="justify-center align-center">
            <v-img width="800px" :src="AssetHelper.maintenance" style="max-width: 100%"></v-img>
          </v-flex>
          <v-flex shrink class="justify-center align-center">
            <h2 style="text-align: center; color: secondary">
              {{ maintenanceMessage }}
            </h2>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" src="./MobileMaintenanceDialog.ts"></script>
