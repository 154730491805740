<template>
  <v-dialog v-model="dialogShown" :width="dialogWidth" persistent>
    <v-card>
      <v-layout xs12 row nowrap pl-4>
        <v-flex xs11 class="pt-3">
          <v-icon v-if="icon">{{ icon }} </v-icon>
          <span class="title">{{ title }}</span>
        </v-flex>
        <Cancel xs1 class="mr-1" v-show="buttonExit" @evtCancel="onClosePressed()" :track="track" />
      </v-layout>
      <v-flex style="max-height: 70vh; overflow-y: auto" ref="contentContainer">
        <v-layout class="bus_normaltext pl-4 mt-3 mb-2 pr-4">
          <div class="message bus_normaltext" v-html="message"></div>
        </v-layout>
        <v-layout class="pl-4 mt-2 mr-4">
          <slot name="additionalcontent"></slot>
        </v-layout>
        <v-layout class="pl-4 pt-3 pr-4" column>
          <slot name="content"></slot>
        </v-layout>
        <v-card-actions>
          <slot name="buttons"></slot>
        </v-card-actions>
      </v-flex>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" src="./SimpleDialog.ts"></script>
