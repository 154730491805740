<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>delete</title>
    <defs>
      <rect x="0" y="0" width="285" height="153"></rect>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-252.000000, -10.000000)">
        <g>
          <mask fill="white">
            <use xlink:href="#path-1"></use>
          </mask>
          <rect stroke="#D4D4D6" x="0.5" y="0.5" width="284" height="152"></rect>
          <text
            mask="url(#mask-2)"
            font-family="HelveticaNeue-Medium, Helvetica Neue"
            font-size="14"
            font-weight="400"
            line-spacing="20"
            fill="#171E3D"
          >
            <tspan x="98" y="28">123.45.678.9</tspan>
          </text>
        </g>
        <g transform="translate(252.000000, 10.000000)">
          <rect x="0" y="0" width="24" height="24"></rect>
          <g transform="translate(3.000000, 2.500000)" stroke="#003259">
            <path
              d="M15.5,3 L15.5,17.25 C15.5,18.2164983 14.7164983,19 13.75,19 L4.25,19 C3.28350169,19 2.5,18.2164983 2.5,17.25 L2.5,3"
            ></path>
            <line x1="0" y1="3" x2="18" y2="3"></line>
            <polyline points="5.5 2.98 5.5 0 12.5 0 12.5 2.98"></polyline>
            <line x1="6.5" y1="5.5" x2="6.5" y2="16.5"></line>
            <line x1="11.5" y1="5.5" x2="11.5" y2="16.5"></line>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
