<template>
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-1411.000000, -539.000000)" style="fill: var(--v-secondary-base)">
        <g transform="translate(92.000000, 109.000000)">
          <g transform="translate(0.000000, 72.000000)">
            <g transform="translate(24.000000, 336.000000)">
              <g transform="translate(1292.000000, 18.000000)">
                <g transform="translate(2.000000, 2.000000)">
                  <path
                    d="M16.0716992,23.8352334 L8.07169916,23.8352334 L8.07169916,13.5183198 C6.21785341,12.2591174 5,10.1336089 5,7.72365557 C5,4.53582235 7.13092876,1.8456942 10.0460574,1 L11,6.72365557 L13,6.72365557 L13.9539426,1 C16.8690712,1.8456942 19,4.53582235 19,7.72365557 C19,10.0709067 17.8446944,12.1483218 16.0716992,13.4182849 L16.0716992,23.8352334 Z M14.0716992,12.3907182 L14.9070754,11.792354 C16.2123167,10.8574342 17,9.35829125 17,7.72365557 C17,6.32154184 16.4175371,5.03326764 15.46234,4.11514082 L14.6942542,8.72365557 L9.30574582,8.72365557 L8.53766003,4.11514082 C7.58246287,5.03326764 7,6.32154184 7,7.72365557 C7,9.40238331 7.83130775,10.9372989 9.19545698,11.8638808 L10.0716992,12.4590578 L10.0716992,21.8352334 L14.0716992,21.8352334 L14.0716992,12.3907182 Z"
                    transform="translate(12.000000, 12.417617) rotate(-45.000000) translate(-12.000000, -12.417617) "
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
