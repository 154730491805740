<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-1213.000000, -392.000000)" fill="var(--v-bus_green-base)">
        <g transform="translate(661.000000, 352.000000)">
          <g transform="translate(552.000000, 40.000000)">
            <g transform="translate(0.000000, 3.000000)">
              <rect x="0.045557442" y="9.07407407" width="5.72722128" height="11.6666667" rx="2" />
              <rect x="8.63638936" y="5.18518519" width="5.72722128" height="15.5555556" rx="2" />
              <rect x="17.2272213" y="0" width="5.72722128" height="20.7407407" rx="2" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
