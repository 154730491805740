<template>
  <svg
    width="60px"
    height="60px"
    viewBox="0 0 60 60"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path
        d="M28.1724425,33.1495731 L29.9521634,33.1495731 L29.9521634,26.1536033 L28.1724425,26.1536033 L28.1724425,33.1495731 Z M25.5177626,48.7585208 L25.5177626,19.6191775 L35.2780598,13.7486839 L22.7486839,13.7486839 L22.7486839,37.3886199 L25.5487995,37.3886199 L25.5487995,39.1373038 L21,39.1373038 L21,12 L39.9950637,12 C39.9975319,21.1087057 40,31.1221734 40,40.2321132 L25.5177626,48.7585208 Z M27.2974835,45.4051695 L38.2239813,39.0612191 L38.2202791,14.4609524 L27.2974835,20.9860237 L27.2974835,45.4051695 Z"
        fill="var(--v-bus_white-base)"
      ></path>
    </g>
  </svg>
</template>
