<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="25px"
    height="36px"
    viewBox="0 0 36 36"
    version="1.1"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <circle stroke="#FFFFFF" stroke-width="2" cx="18" cy="18" r="17" />
      <path
        d="M18,20 C20.7184661,20 23.4490361,21.3619902 25.0992796,23.7500474 C26.4664138,25.7284187 27,28.4976704 27,31 C27,36.5228475 8.78716178,36.5228475 8.78716178,31 C8.78716178,29.2118332 9.12102924,26.7162528 9.86102669,25.12202 C11.4065504,21.7923806 14.6387874,20 18,20 Z M18,22 C15.2586648,22 12.8295186,23.4770715 11.6751238,25.9640721 C11.1420706,27.1124691 10.7871618,29.2235596 10.7871618,31 C10.7871618,31.324813 11.2318629,31.7871587 12.3825976,32.2524226 C13.7815968,32.8180646 15.7854047,33.1421356 17.8935809,33.1421356 C20.0017571,33.1421356 22.005565,32.8180646 23.4045642,32.2524226 C24.5552988,31.7871587 25,31.324813 25,31 C25,28.5842002 24.452993,26.3328098 23.4539198,24.8870571 C22.2099288,23.0868854 20.1199754,22 18,22 Z"
        style="fill: var(--v-secondary-base)"
        fill-rule="nonzero"
      />
      <path
        d="M18,9 C21.3137085,9 24,11.6862915 24,15 C24,18.3137085 21.3137085,21 18,21 C14.6862915,21 12,18.3137085 12,15 C12,11.6862915 14.6862915,9 18,9 Z M18,11 C15.790861,11 14,12.790861 14,15 C14,17.209139 15.790861,19 18,19 C20.209139,19 22,17.209139 22,15 C22,12.790861 20.209139,11 18,11 Z"
        style="fill: var(--v-secondary-base)"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>
