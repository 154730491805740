<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-69, -844)">
        <g transform="translate(59, 836)">
          <rect stroke="var(--v-secondary-base)" x="0.5" y="0.5" width="109" height="39" />
          <g transform="translate(10, 8)">
            <rect x="0" y="0" width="24" height="24" />
            <g transform="translate(3, 2.5)" stroke="var(--v-secondary-base)">
              <path
                d="M15.5,3 L15.5,17.25 C15.5,18.2164983 14.7164983,19 13.75,19 L4.25,19 C3.28350169,19 2.5,18.2164983 2.5,17.25 L2.5,3"
              />
              <line x1="0" y1="3" x2="18" y2="3" />
              <polyline points="5.5 2.98 5.5 0 12.5 0 12.5 2.98" />
              <line x1="6.5" y1="5.5" x2="6.5" y2="16.5" />
              <line x1="11.5" y1="5.5" x2="11.5" y2="16.5" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
