<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(4, 1)" fill="#000000" fill-rule="nonzero">
        <path
          d="M14.0408163,0 C15.1228436,0 16,0.909206354 16,2.03076923 L16,19.9692308 C16,21.0907936 15.1228436,22 14.0408163,22 L1.95918367,22 C0.877156408,22 0,21.0907936 0,19.9692308 L0,2.03076923 C0,0.909206354 0.877156408,0 1.95918367,0 L14.0408163,0 Z M14.0408163,1.01538462 L1.95918367,1.01538462 C1.41817004,1.01538462 0.979591837,1.46998779 0.979591837,2.03076923 L0.979591837,19.9692308 C0.979591837,20.5300122 1.41817004,20.9846154 1.95918367,20.9846154 L14.0408163,20.9846154 C14.58183,20.9846154 15.0204082,20.5300122 15.0204082,19.9692308 L15.0204082,2.03076923 C15.0204082,1.46998779 14.58183,1.01538462 14.0408163,1.01538462 Z M14.0408163,13.5384615 L14.0408163,16.9230769 L7.51020408,16.9230769 L7.51020408,13.5384615 L14.0408163,13.5384615 Z M13.0612245,14.5538462 L8.48979592,14.5538462 L8.48979592,15.9076923 L13.0612245,15.9076923 L13.0612245,14.5538462 Z M2.7755102,13.5384615 C3.2263549,13.5384615 3.59183673,13.9172975 3.59183673,14.3846154 C3.59183673,14.8519332 3.2263549,15.2307692 2.7755102,15.2307692 C2.32466551,15.2307692 1.95918367,14.8519332 1.95918367,14.3846154 C1.95918367,13.9172975 2.32466551,13.5384615 2.7755102,13.5384615 Z M14.0408163,2.36923077 L14.0408163,12.1846154 L1.95918367,12.1846154 L1.95918367,2.36923077 L14.0408163,2.36923077 Z M13.0612245,3.38461538 L2.93877551,3.38461538 L2.93877551,11.1692308 L13.0612245,11.1692308 L13.0612245,3.38461538 Z"
        />
      </g>
    </g>
  </svg>
</template>
