<template>
  <div class="mobile-navigation-drawer">
    <v-bottom-nav class="primary" :value="drawer" dark fixed>
      <v-btn class="bus_icon_whitening" @click="showDashboard" v-track="'MobileNavigation::MobileDashboard'">
        <v-icon>$vuetify.icons.bus_navigation_dashboard</v-icon>
      </v-btn>
      <v-btn @click="showAlarms" v-track="'MobileNavigation::Alarms'">
        <v-icon class="bus_icon_whitening">$vuetify.icons.bus_navigation_alarm</v-icon>
        <v-badge class="mt-1" :color="badgeColor" :v-model="alarmsShown">
          <template v-if="unreadAlarmCount > 0" #badge>
            <span :style="colorText">{{ $format.badgeNumber(unreadAlarmCount) }}</span>
          </template>
        </v-badge>
      </v-btn>
      <v-btn class="bus_icon_whitening" @click="showUserMenu" v-track="'MobileNavigation::MobileUserMenu'">
        <v-icon>$vuetify.icons.bus_navigation_user</v-icon>
      </v-btn>
    </v-bottom-nav>
    <MobileUserMenu :menuShown="userMenuShown" @evtClose="closeUserMenu()" />
  </div>
</template>

<script lang="ts" src="./MobileNavigationDrawer.ts"></script>
<style scoped src="./MobileNavigationDrawer.css"></style>
